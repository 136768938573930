<template>
	<div style="background: #fff;padding: 15px;">
		<div class="filter-container">
			<!-- 关键字搜索 -->
			<div class="filter-item">
				<button-permissions :datas="'addAgreement'">
					<el-button type="primary" style="width:90px;" @click="AddEmployee">添加</el-button>
				</button-permissions>
			</div>
		</div>
		<div class="table-container">
			<el-table :data="tableData" v-loading="loading" border style="width: 100%">
                <el-table-column prop="serviceCode" label="编码"></el-table-column>
				<el-table-column prop="serviceName" label="协议名称"></el-table-column>
				<el-table-column prop="createTime" label="创建时间"></el-table-column>
				<el-table-column label="操作" width="300" align="center">
					<template slot-scope="scope">
						<button-permissions :datas="'editAgreement'" style="margin-right: 10px;">
							<el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
						</button-permissions>
						<button-permissions :datas="'delAgreement'">
						<el-button type="text" @click="handleLeave(scope.row)" style="color: #F56C6C !important;">删除</el-button>
						</button-permissions>
					</template>
				</el-table-column>
			</el-table>
			<div style="text-align: right;">
				<el-pagination
					v-if="total"
					style="margin-top:20px;"
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page=" pageNo"
					:page-sizes="[10, 20, 30, 40, 50]"
					:page-size="pageSize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="total"
				></el-pagination>
			</div>
		</div>

		<!-- 添加员工弹窗 -->
		<el-dialog
			:title="`${editDiaTitle}协议`"
			:visible.sync="dialogFormVisible"
			v-if="dialogFormVisible"
		>
			<el-form :model="ruleForm" ref="ruleForm">
				<el-form-item label="协议名称 :" label-width="150px" prop="serviceName" :rules="[{required:true,message:'协议名称不能为空',trigger: 'blur'}]">
					<el-input placeholder="请输入协议名称" v-model="ruleForm.serviceName" style="width: 300px;"></el-input>
				</el-form-item>
                <el-form-item label="协议编码 :" label-width="150px" prop="serviceCode" :rules="[{required:true,message:'协议编码不能为空',trigger: 'blur'}]">
					<el-input placeholder="请输入协议编码" @input="formatParseInt(ruleForm,'serviceCode')" v-model="ruleForm.serviceCode" style="width: 300px;"></el-input>
				</el-form-item>
				<div class="baseBottom-box" style="margin-bottom:150px;">
                    <quill-editor ref="myTextEditor" v-model="ruleForm.serviceDesc" :options="quillOption" style="min-height: 300px;height:400px;"></quill-editor>
                </div>
			</el-form>
			<div style="padding-top: 20px;">
				<el-button @click="cancelSave" style="width: 100px;margin-left: 100px;">关 闭</el-button>
				<el-button
					type="primary"
					@click="submitSave"
					style="width: 100px;margin-left: 30px;"
					:loading="loading"
				>保 存</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import {
	getSericeList,
	addSerice,
	updateSerice,
	delSerice
} from '@/api/setting'
import buttonPermissions from '@/components/buttonPermissions';
import quillConfig from '@/components/quill-config.js'
export default {
	components: {
		buttonPermissions
	},
	name: 'agreement',
	data () {
		return {
            editDiaTitle: '添加',
            quillOption: quillConfig, //富文本配置
			loading: false,
			tableData: [],
			pageNo: 1,
			pageSize: 20,
			total: 0,
			dialogFormVisible: false,
			ruleForm: {
                id: '',
                serviceCode: '',    //服务编码
                serviceDesc: '',   //富文本
                serviceName: '',    //服务名称
			},
		}
	},
	mounted () {
        
	},
	beforeMount () {
		this.getList();
	},
	computed: {

	},
	methods: {
		async getList () {
			this.loading = true;
			const res = await getSericeList({
				pageSize: this.pageSize,
				pageNo: this.pageNo,
			})
			this.loading = false;
			if (res.success) {
				this.tableData = res.data.records;
				console.log(this.tableData);
				this.total = res.data.total;
			}
		},
		// 查询
		handleFilter () {
			this.pageNo = 1;
			this.getList();
		},

		// 切换显示条数
		handleSizeChange (val) {
			this.pageSize = val;
			this.getList();
		},
		// 翻页
		handleCurrentChange (val) {
			this.pageNo = val;
			this.getList();
		},
		// 添加员工
		AddEmployee () {
			this.editDiaTitle = '添加'
			this.ruleForm = {
				id: '',
				serviceCode: '',    //服务编码
                serviceDesc: '',   //富文本
                serviceName: '',    //服务名称
			}
            this.dialogFormVisible = true;
            this.$nextTick(function() {
                if (this.$refs.myTextEditor.quill) {
                    this.$refs.myTextEditor.quill.enable(true);
                    this.$refs.myTextEditor.quill.blur();
                    window.scrollTo(0, 0);
                }
            });
		},
		// 编辑员工信息
		handleEdit (row) {
			this.editDiaTitle = '编辑'
			this.ruleForm = {
				id: row.id,
				serviceCode: row.serviceCode,    //服务编码
                serviceDesc: row.serviceDesc,   //富文本
                serviceName: row.serviceName,    //服务名称
			}
            this.dialogFormVisible = true;
            this.$nextTick(function() {
                if (this.$refs.myTextEditor.quill) {
                    this.$refs.myTextEditor.quill.enable(true);
                    this.$refs.myTextEditor.quill.blur();
                    window.scrollTo(0, 0);
                }
            });
		},

		// 删除
		async handleLeave (row) {
			this.$confirm('删除后将无法恢复, 是否继续?', '删除', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(async () => {
				let result = await delSerice({
					id: row.id
				});
				if (result.success) {
					this.$message({
						type: 'success',
						message: '删除成功!'
					});
					this.handleFilter()
				} else {
					this.$message({
						type: 'error',
						message: result.alertMsg
					});
				}
				this.getMenlist();
			}).catch(() => {
				console.log('取消删除');
			});
		},

		// 保存员工信息
		submitSave () {
			this.$refs['ruleForm'].validate(async (valid) => {
				if (valid) {
					try {
						this.loading = true
						let data = {
							id: this.ruleForm.id,
                            serviceCode: this.ruleForm.serviceCode,    //服务编码
                            serviceDesc: this.ruleForm.serviceDesc,   //富文本
                            serviceName: this.ruleForm.serviceName,    //服务名称
						}
						let result;
						if (this.ruleForm.id) {
							result = await updateSerice(data);
						} else {
							result = await addSerice(data);
						}

						if (result.success) {
							if (this.editDiaTitle == '添加') {
								this.$message({
									showClose: true,
									type: 'success',
									message: '添加成功！'
								});
							} else {
								this.$message({
									showClose: true,
									type: 'success',
									message: '保存成功！'
								});
							}
							this.dialogFormVisible = false;
                            this.$refs['ruleForm'].resetFields();
                            this.handleFilter()
						} else {
							this.$message({
								showClose: true,
								type: 'error',
								message: result.alertMsg
							});
						}
					} catch (err) {
						console.log(err);
					} finally {
						this.loading = false
					}
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
        //关闭弹窗
		cancelSave () {
			this.dialogFormVisible = false;
			this.$refs['ruleForm'].resetFields();
		},
	},
}
</script>
<style rel="stylesheet/less" lang="less" scoped>
</style>
<style>
.valitedata-dialog-alert {
	width: 550px !important;
}
</style>
